.title-area {
  margin-bottom: 5px;
}

.page-title {
  display: inline;
  font-size: 2em;
}

.print-button {
  float: right;
  margin-top: 10px;
}
